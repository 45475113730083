*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
}

.page-section{
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
}

.title-nav{
  width: 100vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-nav img{
  height: 15vh;
  filter: brightness(0) saturate(100%) invert(22%) sepia(58%) saturate(6416%) hue-rotate(353deg) brightness(90%) contrast(126%);
}

.title-nav ul{
  list-style-type: none;
  display: flex;
  gap: 5vw;
  margin-top: 2vh;
}

.title-nav ul li{
  letter-spacing: 0.1rem;
  transition: 1s;
  cursor: pointer;
}

.title-nav ul li:hover{
  letter-spacing: 0.25rem;
}

.page-section.hero{
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-cta{
  position: absolute;
  bottom: 5vh;
  left: 5vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 5;
  padding: 2vh;
  max-width: 800px;
}

.hero-cta h2{
  color: #ffffff;
  font-size: 4rem;
  line-height: 90%;
  overflow: hidden;
}

.hero-cta p{
  color: #ffffff;
  margin-top: 1vh;
}

.hero-cta button{
  background-color: #ff0000;
  color: #ffffff;
  padding: 1vh;
  border: none;
  font-weight: 800;
  margin-top: 1vh;
}

.about{
  width: 100vw;
  background-color: rgb(20, 20, 20);
  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: 5vh;
  row-gap: 5vh;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 8vh 0;
}

.about h2{
  font-size: 12vw;
  font-weight: 100;
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.1);
  line-height: 90%;
  overflow: hidden;
  z-index: 1;
}

.about div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  position: relative;
  z-index: 2;
}

.about div img{
  width: 75px;
  height: 75px;
  filter: invert(1)
}

.about div h1{
  font-size: 2vh;
  color: #ffffff;
  text-align: center;
  margin-top: 1vh;
}

.about div p{
  color: #ffffff;
  opacity: 0.5;
  text-align: center;
}

.services{
  width: 100vw;
  position: relative;
  padding: 5vh;
  overflow: visible;
}

.services h2{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  padding-right: 2vh;
  text-align: right;
  line-height: 90%;
  overflow: hidden;
  font-size: 15vh;
  font-weight: 100;
  opacity: 0.25;
}

.service-tile{
  height: 20vh;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 5vh;
}

.service-tile:last-of-type{
  margin-bottom: 0;
}

.service-tile img{
  height: 100%;
  width: 20vw;
  margin-right: 2vh;
  object-fit: cover;
  object-position: center;
  filter: grayscale(1) contrast(0.8)
}

.service-tile img:hover{
  filter: grayscale(0) contrast(1);
}

.service-tile div{
  width: 50vw;
  max-width: 500px;
}

.service-tile div h1{
  color: #ff0000;
  font-size: 1.5rem;
}

.gallery-tile{
  height: 25vh;
  width: 25vh;
  margin: 0.5vh;
  object-fit: cover;
  object-position: center;
}

.contact{
  width: 100vw;
  height: 100vh;
  color: #ffffff;
  background-size: cover;
  background-position: center;
}

.contact-container{
  width: 100%;
  height: 100%;
  padding: 5vh;
  background: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0))
}

.contact-container h2{
  font-size: 6vw;
  font-weight: 100;
}

.line{
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  margin: 1vh 0;
}

form{
  width: 90vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

form h4{
  font-size: 0.8rem;
}

input[type=text]{
  background: none;
  border: none;
  border-bottom: 2px solid #ffffff;
  padding: 0.5vh 0;
  font-size: 1rem;
  margin-top: 2vh;
  color: #ffffff;
  outline: none;
}

::placeholder{
  color: #ffffff;
  opacity: 0.5;
}

textarea{
  background: none;
  border: none;
  border-bottom: 2px solid #ffffff;
  padding: 0.5vh 0;
  font-size: 1rem;
  margin-top: 2vh;
  height: 6rem;
  resize: none;
  color: #ffffff;
  outline: none;
}

form button{
  padding: 0.5vh;
  font-size: 1.8rem;
  margin-top: 2vh;
  color: #ffffff;
  border: none;
  background-color: rgb(255, 0, 0);
  cursor: pointer;
}

form button:hover{
  background-color: #ffffff;
  color: #ff0000
}

label{
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.contact-container a{
  font-size: 1.2rem;
  font-weight: 800;
  margin-top: 1vh;
  text-decoration: none;
  color: #ffffff;
}

.contact-container p{
  font-weight: 100;
  font-size: 2rem;
}

@media(max-width: 1000px){
  .hero-cta{
    width: calc(100vw - 10vh);
  }
  .hero-cta h2{
    font-size: 6.9vw;
  }
  .hero-cta button{
    font-size: 2vh;
  }
  .service-tile{
    height: auto;
    min-height: 20vh;
  }
  .services{
    padding: 5vh 2vh;
  }
  .service-tile img{
    height: 30vh;
    width: 30vw;
  }
  .service-tile div{
    width: 55vw;
  }
  .services h2{
    opacity: 0.05;
  }
  .contact-container{
    background: linear-gradient(to right, rgb(0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0));
    padding: 2vh;
  }
  input:first-of-type{
    margin-top: 5vh;
  }
  .line:last-of-type{
    margin-top: 5vh;
  }
  input[type=text]{
    font-size: 1.3rem;
  }
  textarea{
    font-size: 1.3rem;
  }
  .about{
    overflow-y: hidden;
  }
  .about h2{
    font-size: 10.9vw;
    text-align: left;
    transform: rotate(90deg);
    position: absolute;
    left: -40%;
    top: 50vw;
  }
}